<template>
  <div class="directiveTypesWrap">
    <div class="searchWrap">
      <span class="label"> 指令类型名称： </span>
      <el-input
        v-model="searchValue"
        placeholder="请输入内容"
        clearable
      ></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
    </div>
    <div class="operations">
      <el-button
        type="primary"
        @click="$router.push('/privateResource/directiveTypes/add')"
        >添加</el-button
      >
      <el-button
        type="danger"
        @click="deleteDirectiveTypes(selectedDirectives.map((item) => item.id))"
        >批量删除</el-button
      >
    </div>
    <div class="directiveTypesList">
      <el-table
        ref="multipleTable"
        :data="directivesListData.result"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :prop="c.key"
          :label="c.label"
          show-overflow-tooltip
          v-for="c in columns"
          :key="c.key"
        >
          <template slot-scope="scope">
            <span v-if="c.key == 'create_time'">{{
              getTime(scope.row.create_time)
            }}</span>
            <span v-else>{{ scope.row[c.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          width="150px"
          key="operation"
        >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="toEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteDirectiveTypes([scope.row.id])"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-if="directivesListData.total > 8"
        :handleSizeChange="handleSizeChange"
        :handleCurrentPageChange="handleCurrentPageChange"
        :total="directivesListData.total"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      columns: [
        {
          key: "type_name",
          label: "指令类型名称",
        },
        {
          key: "create_time",
          label: "创建时间",
        },
      ],
      directivesListData: {},
      selectedDirectives: [],
      searchValue: "",
    };
  },
  mounted() {
    this.queryDirectiveTypesList({});
  },
  methods: {
    search() {
      this.queryDirectiveTypesList({
        type_name: this.searchValue,
      });
    },
    deleteDirectiveTypes(ids) {
      this.$request.deleteDirectiveTypes({ ids }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.queryDirectiveTypesList({});
      });
    },
    queryDirectiveTypesList({ page_num = 1, page_size = 8, type_name }) {
      this.$request
        .queryDirectiveTypesList({
          page_num,
          page_size,
          type_name,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.directivesListData = res?.data || {};
        });
    },
    getTime(t) {
      return moment(t).format("YYYY-MM-DD HH:mm");
    },
    handleSizeChange(v) {
      this.queryDirectiveTypesList({ page_size: v });
    },
    toEdit(id) {
      this.$router.push({
        path: "/privateResource/directiveTypes/edit",
        query: {
          id,
        },
      });
    },
    handleCurrentPageChange(v) {
      this.queryDirectiveTypesList({ page_num: v });
    },
    handleSelectionChange(val) {
      this.selectedDirectives = val;
    },
  },
};
</script>

<style lang='scss' scoped>
.directiveTypesWrap {
  background-color: #fff;
  .searchWrap {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 0;
    align-items: center;
    .label {
      margin-left: 16px;
    }
    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .operations {
    margin: 24px 24px 0;
  }
  .directiveTypesList {
    padding: 10px 24px 24px;
  }
}
</style>